<template>
  <div :class="['inspectionOperate', isEdit ? 'inspectionOperate-pd' : '']">
    <div class="common-block">
      <div class="item">VIN：{{ detail.driveCar?.vinCode }}</div>
      <div class="item">{{ $t('车牌') }}：{{ detail.driveCar?.carNumber }}</div>
    </div>
    <div class="common-block">
      <p class="tips">{{ $t('请根据要求对车辆进行拍照') }}</p>
      <div v-for="(item, groupIndex) in pictureGroup" :key="groupIndex" class="group">
        <p class="name">{{ item.groupName }}</p>
        <div class="content">
          <div v-for="(category, index) in item.category" :key="index" class="pic-item">
            <van-uploader
              v-model="category.fileList"
              class="uploadBox"
              :deletable="isEdit"
              accept="image/*"
              :disabled="!isEdit"
              :preview-options="{closeable: true}"
              :before-read="beforeRead"
              :after-read="
                (file) => {
                  afterRead(file, index, 'fileList');
                }
              "
              :before-delete="
                (file) => {
                  beforeDelete(file, groupIndex, index, 'fileList');
                }
              "
              :max-count="1"/>
            <p>{{ category.categoryName }}</p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isEdit && detail.inspectResult === '2027004'" class="common-footer">
      <van-button class="btn back" @click="goBack">{{$t('取消')}}</van-button>
      <van-button class="btn submit" :loading="submitLoading" @click="onSubmit">{{ $t('提交') }}</van-button>
    </div>
  </div>
</template>
<script>
import loading from '@/utils/loading'
import { mapGetters } from 'vuex'
import inspectionServices from '@/services/inspectionServices'
import { uploadFile } from '@/utils/cos'
import { v4 as uuidv4 } from 'uuid'
export default {
  data() {
    return {
      submitLoading: false,
      pictureGroup: [
        {
          groupName: this.$t('外观'),
          category: [
            { categoryName: this.$t('正前方'), key: 'frontUrl', fileList:[] },
            { categoryName: this.$t('左前侧'), key: 'leftFrontUrl', fileList:[] },
            { categoryName: this.$t('正后方'), key: 'behindUrl', fileList:[] },
            { categoryName: this.$t('右后侧'), key: 'rightBehindUrl', fileList:[] },
          ]
        },
        {
          groupName: this.$t('内饰'),
          category: [
            { categoryName: this.$t('前排位置'), key: 'frontRowUrl', fileList:[] },
            { categoryName: this.$t('后排位置'), key: 'behindRowUrl', fileList:[] },
          ]
        },
        {
          groupName: this.$t('电量'),
          category: [
            { categoryName: this.$t('电量'), key: 'electricQuantityUrl', fileList:[] },
          ]
        },
      ],
      detail: {
        driveCar: {}
      },
      isEdit: true
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'dictHash']),
    judgePc() {
      return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)
    },
    inspectUrl() {
      let obj = {
        behindRowUrl: '',
        behindUrl: '',
        electricQuantityUrl: '',
        frontRowUrl: '',
        frontUrl: '',
        leftFrontUrl: '',
        rightBehindUrl: '',
      }
      this.pictureGroup.forEach(item => {
        item.category.forEach(item1 => {
          if (item1.fileList && item1.fileList.length > 0) {
            obj[item1.key] = item1.fileList[0].cosUrl
          }
        })
      })
      return obj
    },
  },
  watch: {
    detail: {
      handler(val) {
        const { inspectUrl = {} } = val
        this.pictureGroup.forEach(item => {
          item.category.forEach(item1 => {
            if (inspectUrl[item1.key]) {
              item1.fileList = [{
                url: inspectUrl[item1.key],
                status: 'done'
              }]
            } else {
              item1.fileList =[]
            }
          })
        })
      },
      deep: true
    }
  },
  mounted() {
    this.isEdit = this.$route.query.operate === 'view' ? false : true
    this.getDetail()
  },
  methods: {
    // 图片校验
    beforeRead(file) {
      // const str = file.name.substring(file.name.lastIndexOf('.') + 1)
      // const rules = ['jpg','png','jpeg']
      // if (!rules.includes(str.toLocaleLowerCase())) {
      //   this.$toast.fail(this.$t('请上传正确图片文件(支持jpg/jpeg/png)'))
      //   return false
      // }
      if (!file.type.includes('image')) {
        this.$toast.fail(this.$t('请上传正确图片文件'))
        return false
      }
      return true
    },
    afterRead(file, index, type) {
      const uuid = uuidv4()
      const content = file.content
      file.status = 'uploading'
      file.message = this.$t('上传中...')
      file.uuid = uuid
      uploadFile(file.file).then(({ url }) => {
        if (file) {
          file.status = 'done'
          file.message = ''
          file.url = content
          file.cosUrl = url
        }
      }).catch(() => {
        file.status = 'failed'
        file.message = this.$t('上传失败')
      })
    },
    beforeDelete(file, groupIndex, index, type) {
      this.pictureGroup[groupIndex].category[index].fileList = this.pictureGroup[groupIndex].category[index].fileList.filter(item => item.uuid !== file.uuid)
    },
    // 取消
    goBack() {
      if (window.history.length > 1) {
        this.$router.go(-1)
      } else {
        window.close()
      }
    },
    validate() {
      let flag = true
      for (let i = 0; i < this.pictureGroup.length; i++) {
        const item = this.pictureGroup[i].category
        for (let m = 0; m < item.length; m++ ) {
          const item1 = item[m]
          if (item1.fileList.length == 0) {
            this.$toast(`${this.$t('请拍摄上传')} ${item1.categoryName} ${this.$t('照片')}`)
            flag = false
            return false
          }
        }
        if (!flag) {
          return false
        }
      }
      return flag
    },
    // 提交
    async onSubmit() {
      let params = {
        id: this.$route.query.taskId,
        inspectUrl: this.inspectUrl
      }
      if (!this.validate()) {
        return false
      }
      if (this.submitLoading) return false
      loading.showLoading()
      this.submitLoading = true
      try {
        const res = await inspectionServices.queryInspect(params)
        this.$toast.success(this.$t('操作成功！'))
        setTimeout(() => {
          this.submitLoading = false
          loading.hideLoading()
          // this.$router.go(-1)
          this.$router.push({
            path: '/inspection-list'
          })
        }, 1500)
      } catch {
        this.submitLoading = false
      }
    },
    // 详情
    async getDetail() {
      loading.showLoading()
      const res = await inspectionServices.inspectDetail({ id: this.$route.query.taskId })
      loading.hideLoading()
      this.detail = Object.assign(this.detail, res)
    },
  },
}
</script>
<style lang="less" scoped>
.inspectionOperate{
  padding: 16px;
  font-size: 13px;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  &.inspectionOperate-pd{
    padding-bottom: 96px;
  }
  .uploadBox{
    &.hide{
      /deep/ .van-uploader__upload{
        display: none;
      }
    }
    /deep/ .van-uploader__wrapper--disabled{
      opacity: 1;
    }
  }
  .common-block{
    padding: 12px;
    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
    .item{
      &:not(:last-of-type) {
        margin-bottom: 8px;
      }
    }
    .tips{
      margin-bottom: 8px;
    }
    .group{
      .name{
        padding: 8px 0;
        font-weight: 500;
        &::before{
          content: '*';
          color: #EED484;
        }
      }
      .content{
        display: flex;
        flex-wrap: wrap;
        .pic-item{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-bottom: 8px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>